.slider-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.slider-inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    touch-action: none;
    transition: transform 1s ease-in-out 0s;
    // transform: translateY(0vh);
    // transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}